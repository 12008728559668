import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ร้านค้าเบนจามิน"
    }}>{`ร้านค้าเบนจามิน`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "เบ็ดตกปลา"
    }}>{`เบ็ดตกปลา`}</h3>
    <p>{`ไอเทมเบ็ดตกปลา ใช้สำหรับนำไปตกปลาที่บ่อน้ำในฟาร์มของคุณ มีเบ็ดตกปลาขายอยู่ 2 ชนิด`}</p>
    <ul>
      <li parentName="ul">{`คันเบ็ดเก่า`}</li>
      <li parentName="ul">{`คันเบ็ดเหล็ก`}</li>
    </ul>
    <p><img alt="mat" src={require("./public/images/fish-1.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      